<template>
    <footer>
        <v-container>
            <v-row>
                <v-col cols="12" md="4" class="pa-10">
                    <v-img
                            :src="require('../assets/img/logo-gr-white.svg')"
                            class="my-3"
                            contain
                            height="100"
                    />
                </v-col>

                <v-col cols="12" md="4" class="pa-10">
                    <h5>Enlaces</h5>
                    <p><a href="/aviso-legal-esp">Aviso legal</a></p>
                </v-col>

                <v-col cols="12" md="4" class="pa-10">
                    <h5>Contacto</h5>
                    <p><v-icon color="cyan">mdi-email-outline</v-icon> <a href="mailto:contact@gestoriariola.com">contact@gestoriariola.com</a></p>
                    <p><v-icon color="green">mdi-whatsapp</v-icon> <a href="tel:639086934">639086934</a></p>
                    <p><v-icon color="blue">mdi-phone</v-icon> <a href="tel:977241707">977241707</a></p>
                    <address><v-icon color="red darken-4">mdi-google-maps</v-icon> <a href="https://goo.gl/maps/oB7HnhuBQtyNxuJt9" target="_blank">Rambla Nova 110 43001 Tarragona</a></address>
                </v-col>

            </v-row>
            <v-row class="text-center">
                <v-col cols="12" class="pa-10">
                    <hr>
                    <p class="pa-10 text-center">Copyright © Gestoria Riola 2022</p>
                </v-col>
            </v-row>
        </v-container>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>